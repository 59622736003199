
@media screen and (min-width: 39.9375em) {
  .pages-container {
    height: 32rem;
    overflow: auto;
  }
}

.pages-container::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

/* Handle */
.pages-container::-webkit-scrollbar-thumb {
  background: #df634a2f;
  border-radius: 100vh;
  border: 0px solid #edf2f7;
}

.p-dialog-content::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f6f8fc;
}

.p-dialog-content::-webkit-scrollbar-thumb {
  background: #4cbbb5ab;
  border-radius: 100vh;
  border: 0px solid #edf2f7;
}

/* Handle */
.fbcontainer::-webkit-scrollbar-thumb {
  background: #48b2bc70;
  border-radius: 100vh;
  border: 0px solid #edf2f7;
}

.linkedin-container::-webkit-scrollbar-thumb {
  background: #48b2bc70;
  border-radius: 100vh;
  border: 0px solid #edf2f7;
}
.instacontainer::-webkit-scrollbar-thumb {
  background: #48b2bc70;
  border-radius: 100vh;
  border: 0px solid #edf2f7;
}

.fbcontainer::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #05405d;
}
.linkedin-container::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #05405d;
}
.instacontainer::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #05405d;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}

/* Track */
.pages-container::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #429ebd5b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #429ebd5b;
  border-radius: 100vh;
  border: 0px solid #edf2f7;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #232349;
} */
