.lang_btn {
    margin-left: 5px;
    padding-left: 10px;
    position: relative;
}
.lang_btn:before {
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    width: 2px;
    height: 15px;
    margin: auto;
    position: absolute;
    background: black;
}