
.q h3 {
    font-weight: bold;
    margin-bottom: 0px;
}

.field_radiobutton {
    margin: 0px 0;
}

.field_radiobutton label {
    margin-left: 0px;
    cursor: pointer;
}

.field_radiobutton .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #596ae9;
}

.field_radiobutton .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #596ae9;
    background: #596ae9;
}

.bexample {
    text-align: center;
    border: 0px solid #ccc;
    border-radius: 5px;
    float: right;
    padding: 5px;
    margin: 10px;
    font-size: 10px;
    color:#ccc;
}

.bexample img {
    /* max-width: 70px;     */
    height: 60px; 
    width: auto !important;   
}

.type {
    font-size: 40px;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    text-align: center;
}

.desc {
    margin: 10px 0;
}

.examples {
    margin: 10px 0;
}

.msg {
    margin: 10px 0;
}

