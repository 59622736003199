@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("~primeicons/primeicons.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300;400;500&display=swap");
@import url("~primereact/resources/themes/lara-light-blue/theme.css");
@import url("~primereact/resources/primereact.min.css");
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;500;700;900&display=swap');

.social-container::-webkit-scrollbar {
  display: none;
}

.drawer-bar::-webkit-scrollbar {
  display: none;
}

.p-inputtext {
  border: none !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.p-tooltip-arrow {
  /* background-color: none !important; */
  /* background-color: rgba(0, 89, 255) !important; */
}

.p-tooltip-text {
  background-color: #596ae9 !important;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #596ae9 !important;
  background-color: var(--fc-button-active-bg-color, #596ae9) !important;
  border-color: #596ae9 !important;
  border-color: var(--fc-button-active-border-color, #596ae9) !important;
}

.fc-toolbar-title {
  color: #596ae9;
  font-weight: 500;
}

.fc-col-header-cell-cushion {
  font-weight: 600;
}

.fc-toolbar h2 {
  display: inline;
  font-size: 16px !important;
  font-weight: 500;
  margin-left: 13px !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: unset !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: none !important;
  color: unset !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: unset !important;
  outline: unset !important;
}

.p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #fff !important;
  color: unset !important;
}

.p-datatable .p-sortable-column {
  background: #fff !important;
  color: unset !important;
}

.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tfoot {
  background-color: #fff !important;
}

.p-virtualscroller-content {
  display: flex;
  flex-wrap: wrap;
  position: initial;
  justify-content: space-around;
  /* justify-content: center; */
  top: 0;
  left: 0;
  contain: content;
  min-height: initial;
  min-width: initial;
}

.p-dropdown {
  background: unset !important;
  border: unset !important;
  transition: unset !important;
  border-radius: unset !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: unset !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: unset !important;
  outline-offset: unset !important;
  box-shadow: unset !important;
  border-color: unset !important;
}

.p-button {
  background: #596ae9 !important ;
  border: #596ae9 !important;
  
  min-width: 2rem !important;
  color: #fff !important;
}

.save_rep {
  background: #7f56d9 !important;
}

.inputTextarea_style {
  border: 1px solid #d0d5dd !important;
}
.p-paginator .p-button {
  height: 2rem !important;
  width: 2rem !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
  background: #596ae9 !important ;
  border: #596ae9 !important;
  color: #fff !important;
}

.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  box-shadow: none !important;
}

.p-tag {
  background: #596ae9 !important;
  /* border-radius: 25% !important; */
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #596ae9 !important;
  border-color: #596ae9 !important;
}

.p-button:focus {
  box-shadow: unset  !important;
}

/*scrollbar*/

::-webkit-scrollbar {
  width: 5px;
  cursor: pointer !important;
}
  ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
  ::-webkit-scrollbar-thumb {
  background: #596ae9c4; 
    border-radius:10px;
    cursor: pointer !important;
}
::-webkit-scrollbar-thumb:hover {
  
  background: #808ceb; 
} 

.p-radiobutton .p-radiobutton-box {  
  width: 16px !important;
  height: 16px !important;
}
.Rectangle-23 {
  width: 1402px;
  height: 1024px;
  flex-grow: 0;
  margin: 0 120px 0 0;
  padding: 0 32px 24px 0;
  background-color: #25345f;
}
.Vector {
  width: 66px;
  height: 132px;
  flex-grow: 0;
  margin: 40px 0 677px 598px;
  transform: rotate(-90deg);
  background-image: linear-gradient(241deg, #596ae9 12%, #22c4ac -96%);
}

img.Slice-1 {
  width: 24px;
  height: 40px;
  margin: 0 16px 0 0;
  padding: 0 0 0 0px;
  object-fit: contain;
  /* background-position-x: 872px !important; */

}
.Union {
  width: 79.2px;
  height: 24px;
  flex-grow: 0;
  margin: 8px 0 8px 16px;
  padding: 0 20.7px 0 0;
  background-color: #2c2072;
  /* background-position-x: 872px; */
  /* background-position-y: 64px; */

}
.login-input{
  padding-top: 5px;
}

.Email-profetionnel {
  width: 160px;
  height: 16px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #152935;
}

.Rectangle-20 {
  width: 456px;
  height: 48px;
  flex-grow: 0;
  margin: 8px 0 0;
  padding: 16px 48.2px 16px 16.1px;
  border-radius: 4px;
  background-color: #fff;
}

.conatiner{
  width: 1440px;
  height: 1024px;
  flex-grow: 0;
  padding: 0 112px 0 0;
  background-color: #f4f7fb;
}

.checkbox {
  width: 18px;
  height: 18px;
  flex-grow: 0;
  border-radius: 2px;
  border: solid 2px #5596e6;
  background-color: #5596e6;
}


.Account {
  width: 96px;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
 
  padding: 24px 0;
  border-radius: 8px;
  background-color: #fff;
}


.Facebook {
  width: 96px;
  height: 32px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  text-align: center;
  color: #25345f;
}
.Linkedin {
  width: 96px;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  
  padding: 24px 0;
  border-radius: 8px;
  background-color: #596ae9;
}
.Ajouter-Linkedin {
  width: 96px;
  height: 32px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  text-align: center;
  color: #fff;
}

/*------------------- myStyle */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {  
  font-family: Inter;
}

.hide_el {
  display: none !important;
}

.displayFlex {
  display: flex;
}

.align {
  display: flex;
  align-items: center;
}

.align_all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_el {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.left_el {
  display: table;
  margin-right: auto;
}

.right_el {
  display: table;
  margin-left: auto;
}

.full_width {
  width: 100%;
}

/*-- login */
.body_gray {
  background: #f4f7fb;
}

.body_blue {
  background: #25345f;
}

/*-- form_login */
.logo_flex {
  margin-bottom: 55px;
}

.logo_flex img {
  margin-right: 5px;
}

.logo_flex h1 {
  font-size: 25px;
  color: #2c2072;
  font-weight: 700;
}

/*-- connect_block */
.connect_block {
  margin-bottom: 40px;
}

.connect_block h2,
.connect_block h3 {
  font-size: 24px;
  color: #152935;
  font-weight: 700;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
}

.connect_block h3 {
  font-size: 16px;
}

.connect_block p {
  font-size: 14px;
  color: #5a6872;
  margin-bottom: 55px;
  font-family: 'Inter', sans-serif;
}

/*-- input_style */
.form_style {
  position: relative;
  padding: 60px 120px;
}

.form_change{
  margin-bottom: 60px;
}
.btn_chng{
margin-top: 30px;
}
.label_confirm{
  margin-top:20px;
}
.input_style label {
  display: block;
  font-size: 14px;
  color: #152935;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
}

.input_style .box_input:not(:last-of-type) {
  margin-bottom: 25px;
}

.input_style input {
  width: 100%;
  padding: 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(52, 69, 99, 0.4);
  font-family: 'Inter', sans-serif;
}

.input_style input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: rgba(52, 69, 99, 0.4);
}
.input_style input::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: rgba(52, 69, 99, 0.4);
}
.input_style input:-ms-input-placeholder { /* IE 10+ */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: rgba(52, 69, 99, 0.4);
}
.input_style input:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  color: rgba(52, 69, 99, 0.4);
}

.p-error {
  font-size: 14px;
  font-weight: 500;
}

.remeber_me_check label {
  margin-bottom: 0;
  margin-left: 10px;
}

.pwd_forget {
  margin-bottom: 5px;
  margin-top: 35px;
  font-size: 14px;

}

.btn_url,
.btn_url_cover {
  width: 100%;
  color: #fff;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  background: #596ae9;
  font-family: 'Inter', sans-serif;
}

.btn_url_cover {
  outline: none;
  color: #596ae9;
  background: none;
  border: solid 2px #596ae9 !important;
}

.no_compte {
  padding: 15px 0;
  margin-top: 30px;
  border-top: 1px solid #dee0e1;
}

/*-- signup */
.required_pwd {
  margin-bottom: 25px;
}

.required_pwd label {
  font-size: 14px;
  color: #5a6872;
  margin-bottom: 0;
  margin-left: 10px;
  font-family: 'Inter', sans-serif;
}

.choix_cnx {
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid #dee0e1;
  font-family: 'Inter', sans-serif;
}

.choix_cnx label {
  margin-bottom: 0;
  margin-right: 25px;
}

.choice_btn {
  margin-bottom: 60px;
}

.choix_cnx button {
  font-size: 14px;
  font-weight: 500;
  color: #596ae9;
  text-decoration: underline;
}

.choice_btn span,
.style_input_number span {
  font-size: 14px;
  font-weight: 500;
  color: #152935;
  font-family: 'Inter', sans-serif;
}

.choice_btn .btns_style {
  width: 50%;
  margin-left: auto;
}

.choice_btn .btns_style button {
  width: 100%;
}

.choice_btn .btns_style button:first-of-type {
  margin-right: 25px;
}

.style_input_number {
  margin-bottom: 65px;
}

.style_input_number button {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background: #596ae9;
}

.style_input_number input {
  width: 70%;
}

.input_number {
  margin-left: auto;
}

.specific_style_btn {
  display: table;
  margin-top: 100px;
  width: 100% !important;
  background: #596ae9 !important;
}

/*-- box_social */
.box_social {
  margin-bottom: 25px;
  padding-bottom: 170px;
  border-bottom: 1px solid #dee0e1;
}

.box_social .account_social {
  width: 100%;
  padding: 25px 15px;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
}

.box_social .account_social:not(:last-of-type) {
  margin-right: 25px;
}

.box_social .account_social img {
  margin: auto;
  margin-bottom: 15px;
}

.box_social .account_social span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #25345f;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.list_pages_choisie {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  position: absolute;
  padding: 60px 120px;
  background: #f4f7fb;
}

.block_list_page {
  margin-bottom: 25px;
  padding-bottom: 170px;
  border-bottom: 1px solid #dee0e1;
}

.block_list_page h2 {
  color: #152935;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  font-family: 'Inter', sans-serif;
}

.list_pages_choisie h3 {
  font-size: 18px;
  font-weight: 600;
  color: #25345f;
  margin-left: 10px;
}

.box_list_pages {
  padding: 25px;
  margin-top: 25px;
  background: #fff;
}

.style_page {
  padding: 10px;
  border-radius: 8px;
  background: #f7f8fe;
}

.style_page h4 {
  font-size: 14px;
  font-weight: 600;
  color: #25345f;
  margin-left: 15px;
  font-family: 'Inter', sans-serif;
}

.username_page img {
  margin: auto;
}

.username_page h1 {
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  font-weight: 400;
  color: #25345f;
  font-family: 'Inter', sans-serif;
}

.username_page h1>span {  
  color: #596ae9;
}

.username_page p {
  color: #5a6872;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;  
  font-family: 'Inter', sans-serif;
}

.search_page {
  width: 20%;
  margin-left: auto;
  margin-top: 45px;
}

/*-- highcharts */
.highcharts-title,
.style_title_chart h1 {
  font-weight: 600;
  color: #101828 !important;
  font-size: 18px !important;
  font-family: 'Inter', sans-serif;
}

.highcharts-subtitle,
.style_title_chart p {
  font-size: 14px !important;
  color: #5a6872 !important;
  font-family: 'Inter', sans-serif;
}

.highchart_block {
  margin-bottom: 25px;
}

.highchart_block1 {
  padding: 15px;
  border-radius: 8px;
  background: #fff;
}

.style_block {
  margin: 30px;
}

.style_title_chart .highchart_block {
  padding-top: 0;
}

.content_style_title {
  padding: 15px;
  background: #fff;
  border-radius: 8px 8px 0 0;
}

.style_title_chart .highchart_block1 {
  border-radius: 0 0 8px 8px;
}

.stat_pie .title_1 {
  font-size: 14px;
  color: #344563;
  font-family: 'Inter', sans-serif;
}

.stat_pie .sum_title {
  font-size: 24px;
  font-weight: 800;
  color: #152935;
  font-family: 'Inter', sans-serif;
}

.stat_pie .sum_title_2 {
  font-weight: 600;
}

.cat_name {
  width: initial;
}

/*-- table_bench */
.table_bench .p-datatable-tbody > tr > td:not(.name_item) {
  font-size: 20px;
  color: #344563;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

.table_bench .name_item {
  font-size: 14px;
  color: #152935;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.table_bench .p-datatable-header,
.table_bench .p-datatable-header th,
.p-datatable .p-sortable-column {
  background: #f4f7fb !important;
}

/*-- info_chart_fans */
.info_chart_fans {
  padding: 45px 0;
}

.info_chart_fans ul li {
  padding: 5px 0;
  position: relative;
  align-items: center;
  width:20%;
}

.info_chart_fans div ul li:first-of-type {
  width: 100%;
}

.info_chart_fans div ul li:last-of-type {
  width: 20%;
  justify-content: flex-end;
}

.info_chart_fans div ul li:nth-child(3) {
  width: 5%;
}

.info_chart_fans ul {
  /*padding-bottom: 8px;*/
}

.info_chart_fans ul:not(:first-of-type) {
  /* padding-top: 8px; */
}

.audience_total {
  padding: 45px 25px;
}


/*--- info_chart_tabs */
.info_chart_tabs .box_info_chart {
  width: 100%;
  display: flex;
}

.info_chart_tabs .box_info_chart li {
  float: left;
}

button.print_capture {
  background: url('images/capture.png') center no-repeat;
  background-size: contain;
}
.mb-13{
  margin-bottom: 4rem;
}

/*------ carousel_eng */
.carousel_eng	.p-virtualscroller {
  contain: initial
}

.arrowDown {
  transform: rotate(180deg);
}
.arrowDown path {
  stroke: #f04438;
}

.highchart_block .highcharts-container {
  width: initial !important;
}

.highchart_block .highcharts-root {
  width: 100%;
}

/*-- Post library */
.comment_moderation {
  padding-top: 25px;
  padding-bottom: 20px;
}

.comment_moderation a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #d0d5dd;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate 1s linear infinite; /* Vous pouvez ajuster la durée et le type d'animation selon vos préférences */
}

/*-- btn_tag */
.btn_tag button {
  color: #344054 !important;
  font-size: 0.75rem;
  border: 1px solid #d1d5db !important;
  background: #fff !important;
  border-radius: 5px;
  padding: 5px 10px;
}

.wordCloud>div>span:last-of-type {
  font-size: 10px;
}

.word_arabic span {
  font-family: 'Tajawal', sans-serif;
}

.wordCloud .p-tabview-nav a {
  color: #dee2e6 !important;
  box-shadow: none !important;
}
.wordCloud .p-tabview-nav li.p-tabview-selected a {
  color: #6941c6 !important;
}
.wordCloud .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #6941c6 !important;
}
.wordCloud .p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #6941c6 !important;
}

.element_0 {
  font-size: 27px;
  font-weight: 900;
  color: #42307D;
}

.element_1 {
  font-size: 24px;
  font-weight: 700;
  color: #6941C6;
}

.element_2 {
  font-size: 21px;
  font-weight: 500;
  color: #9E77ED;
}

.element_3 {
  font-size: 18px;
  font-weight: 300;
  color: #D6BBFB;
}

.header_rep .word_arabic {
  direction: rtl;
}

.center_header>.p-column-header-content {
  justify-content: center;
}

.nuage_word.hide_nauge {
  width: 0;  
  opacity: 0;
}

.nuage_word.hide_nauge {
  display: none;
}

.nuage_word.hide_nauge .wordCloud {
  overflow: hidden;
}

.content_reporting .spline_sectorielle {display: none;}

.block_abonnement .p-tabview-nav-container  {display: none;}
.block_abonnement .p-tabview .p-tabview-panels {padding: 0;}
.block_abonnement .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {background: #7F56D9 !important;}

.userinfos{
  background-color: rgb(253, 253, 253);
  color: #2d404a;
  width: 450px;
  height: 183px;
  font-size: 12px;
  padding-top: 56px;
  padding-left: 20px;
  border-radius: 10px;
  margin-top: 450px;
  margin-left: 203px;
  
}

.updateinfo{
  margin-left: 782px;
  margin-top: -223px;
}

.username{
  width: 368px;
  height: 41px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.useredit{
  width: 368px;
  height: 41px;
  background-color: #596ae9;
  margin-top: 10px;
}

.labelnoun{
color:rgb(11, 59, 70);
font-weight: 500;
}
.infoslabel{
  color:rgb(11, 59, 70);
  font-weight:700;
}

.protectedimg{
  width: 150px;
  height: 150px;
  border-radius: 80px;
  background-color: #fff;
  padding: 14px;
}

.profilepicture{
  margin-top: -475px;
  margin-left: 344px;
}

.pictureedit{
  height: 30px;
  margin-top: 40px;
}

.filetype{
display: none;
}

.pi-plus-circle{
  background-color: #596ae9;
  color: white;
  border-radius: 3px;
  font-weight: 600;
  font-family: system-ui;
  padding: 7px;
  margin-left: 13px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background-color: rgb(0, 0, 0); /* Semi-transparent black */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal{
  margin-right: 100px;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background-color: rgba(0, 0, 0, 0.705);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Allows scrolling if the content is larger than the screen */
  
}

.skipbutton{
  margin-top: 650px;
  margin-left: 495px;
  margin-right: -541px;
  border-radius: 20px;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  width: 80px;
  transition: all 0.5s;
  cursor: pointer;
}
.backlogin{
  display: table;
  margin-left: auto;
}
.back{
  font-size: 16px;

}
.fc-myCustomButton-button:before {
  content: '\f073'; /* Code de l'icône FontAwesome pour "fa-plus" */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
  border-radius: 5%;
}
.containerdiv {
  display: flex;
}
.box {
  width: 50%; /* Ajuste la largeur selon tes besoins */
  padding: 20px;
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur */
}
.createpost{
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 10px;
  color: #101828;
}
.postin{
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #344054;
  padding-bottom:15px;
}
.postpreview{
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #101828fc ;
}
.demension{
  padding: 20px;
  border-radius:0.5cap;
  margin-top: 30px;
}
.demensionPostPreview{
  height: auto;
}
.describebriefs{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #475467;
}
.generateimageIA{
  font-family: 'Inter', sans-serif;
  background-color: #7f56d9;
  color: white;
  font-size: 14px;
  height: 40px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 20px;
  border-radius: 0.5cap;
}
.button-group {
  display: flex; /* Utilise Flexbox pour aligner les éléments */
  gap: 10px; /* Ajoute un espacement de 10px entre les boutons */
  margin-top: 20px;
}
.addvideo{
  font-family: 'Inter', sans-serif;
  border-radius: 1cap;
  color: #344054;
  font-size: 14px;
  padding: 4px;
  height: 40px;
}
.addphotos{
  font-family: 'Inter', sans-serif;
  border-radius: 1cap;
  color: #344054;
  font-size: 14px;
  padding: 4px;
  height: 40px;
}
.previewfacebook{
  font-family: 'Inter', sans-serif;
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
}
.slectedpage{
  color: #344054;
  font-size: 14px;
  font-weight: bold;

}
.profilepic{
  width: 50px;
  height: 50px;
  border-radius: 50cap;
}
.alinstant{
  color: #475467;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}
.closebutton{
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  color: gray;
  font-size: 20px;
  z-index: 1000; /* corrected from 'Index' to 'z-index' */
  cursor: pointer;
}
.media-preview {
  width: 100%; /* Set the width of the media container */
  position: relative; /* Allows for absolute positioning of the count */
}

.media-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjusts based on screen size */
  gap: 10px;
}

.media-item {
  position: relative; /* To position the close button */
  overflow: hidden; /* Ensures the items stay within the div */
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #7f56d9;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
  background-color: rgb(255, 255, 255); /* Updated to use rgba for better transparency control */
  border: none; /* Remove any default border */
  width: 20px; /* Set width */
  height: 20px; /* Set height equal to width */
  border-radius: 50%; /* Make it circular */
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Optional: Add a subtle shadow for better visibility */
  transition: background-color 0.3s; /* Optional: Add a hover effect */
}

.more-count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
}
.engagement{
  display: flex; /* Utilise Flexbox pour aligner les éléments */
  color: #475467;
  gap: 30px;
  padding: auto;
  margin-top: 10px;
}
.styleengagement{
  font-family: 'Inter';
  font-size: 14px;
  font-weight:bold;
}
.demensiondivmedia{
width: 745px;
height: 500px;
margin-left: -20px;
margin-top: 20px;
}
.demensiondivmedia {
  max-height: 400px; /* Set a maximum height */
  overflow: hidden; /* Hide overflow */
}

.media-preview {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100%; /* Allow the preview to take up full height */
}

.media-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
.button-group-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align buttons and calendar on the left */
}

.button-group {
  display: flex;
  gap: 8px; /* Add space between buttons */
  margin-bottom: 16px; /* Add space between buttons and calendar */
}
.calendar-container {
  margin-top: 8px; /* Ensure some space between buttons and the calendar */
}
.confirmprogram{
  height: 48px;
  border-radius: 0.5cap;
  margin-left: 17px;
  margin-top: 10px;
  background-color: #596ae9;
  color: white;
  width: 108px;
}
.past-date {
  background-color: #f0f0f0; /* Light gray background */
  color: #a0a0a0; /* Gray text color */
  pointer-events: none; /* Prevent interaction */
}
.modaldate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* S'assurer que le modal est au-dessus des autres contenus */
}
.modal-content-date {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.cancelmodal{
  height: 48px;
  width: 108px;
  border-radius: 0.5cap;
  margin-left: 17px;
}
/* Disabled Publish button */
.generateimageIA.disabled {
  background-color: rgb(48, 48, 48);
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

/*-------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
.maintenance_diggow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}
.maintenance_diggow main{
  height: 100%;
  display: flex;
  margin: 0 20px; 
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}
  .maintenance_diggow h1{
    font-size: 3em;
    font-weight: 100;
    color: #6941C6;
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }
  .maintenance_diggow h2{
    font-size: 1.5em;
    font-weight: 100;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
  }
  .maintenance_diggow h3{
    font-size: 1.5em;
    font-weight: 100;
    margin-top: 0;
    font-family: 'Roboto', sans-serif;
  }
  .maintenance_diggow a{
    font-size: 1.5em;
    font-weight: 300;
    color: #6941C6;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
  }

.maintenance_diggow footer{
  position: absolute;
  bottom: 0;
  margin: 10px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}

/*-- loading */
.loading_block .loader {
  width: 16px;
  height: 16px;
  margin: auto;
  display: table;
  margin-top: 25px;
  border-radius: 50%;
  position: relative;
  background-color: #7F56D9;
  box-shadow: 32px 0 #7F56D9, -32px 0 #7F56D9;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #0002;
    box-shadow: 32px 0 #0002, -32px 0 #7F56D9;
  }
  50% {
    background-color: #7F56D9;
    box-shadow: 32px 0 #0002, -32px 0 #0002;
  }
  100% {
    background-color: #0002;
    box-shadow: 32px 0 #7F56D9, -32px 0 #0002;
  }
}
      
.p-tooltip-text	{
  width: 225px;
  font-size: 13px;
}

/*-- */
.list_grid {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
}

.dragged {
  background-color: rgb(37, 37, 197);
}
@media (min-width: 600px) {
  .list_grid {
    grid-gap: 24px;
  }
}

.list_vertical {
  user-select: none;
}
@media (min-width: 600px) {
  .list_vertical {
    grid-gap: 24px;
  }
}