/* scrollbar */
  
.p-datatable-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 1px ;
  }
  
  /* Handle */
  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background: #45a0f5;
    border-radius: 150vh;
    border: 0px solid #fff;
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
  }
  
  /* .dropdown-menu::-webkit-scrollbar-thumb {
    background: #cecfcf;
    border-radius: 100vh;
    border: 0px solid #edf2f7;
  } */


 